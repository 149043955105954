@charset "utf-8";

// Dimensions
$max-content-width: 800px;

@import
  "variables",
  "themes",
  "layout",
  "base",
  "distill"
;

.animated-gif{
  width: 3%;
  height: 5%;
  overflow: hidden;
  // border: 1px solid black;
  // margin: -50px 0px 0px 0px;
  margin:-50% 0;
  float: center;
}

// body.animated-gif {
//   margin: -50px 0px 0px 0px;
// }